<template>
  <b-modal
    title="Bitacora de Facturador"
    id="ModalBitacoraFacturador"
    header-class="d-block"
    size="custom-lg-max"
    hide-footer
  >
    <template #modal-header>
      <div class="row">
        <div class="col-sm-3 pr-0">
          <h5 class="modal-title">
            <b>Bitacora de Facturador</b>
          </h5>
        </div>
        <div class="col-sm-3 pr-0">
          <button class="btn btn-orange text-white py-1 px-3">De Hoy</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group row" v-if="tipoEmisionSelected"
          >
            <template v-for="(tipoEmision, index) in tipoEmisions">
              <div class="col-sm-12 text-right"
                :key="index" v-if="tipoEmisionSelected.id == tipoEmision.id">
                  <label>
                    {{ tipoEmisionSelected.descripcion }}
                  </label>
                  <i class="i-Record-2 en-linea"
                    :style="colorIconEstadoFactura(tipoEmisionSelected.cod_clasificador)"
                  ></i>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <div class="row mb-3">
      <div class="col-sm-12">
        <b-table
          :fields="fieldsTable"
          :items="facturasPendientesSiats"
        >
          <template #cell(total)="data">
              {{data.item.total | numeral('0,0.00') }}
          </template>
          <template #cell(estado)="data">
              <span class="text-danger"> {{ data.item.estado }} </span>
          </template>
          <template #cell(opciones)="row">
              <button class="btn btn-block py-2"
              :class="!row.item.estado_validacion ? 'btn-warning' : 'btn-success'"
              :id="'btn-'+row.item.id"
              @click="validacionSucursalFacturador(row.item, row.index)">Verificar</button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-success"
            :disabled="!conexionSiat"
            @click="storeFactura">
            Enviar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import validarParametroFacturacionSiat from '@/store/modules/facturacion/utils/siatValidations';
import { TIPO_EMISION } from '@/utils/globalConventions';
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ModalBitacoraFacturador',
  props: {
    facturasPendientesSiats: {
      type: Array,
      default: Array,
    },
    siatPuntoVentas: {
      type: Array,
      default: Array,
    },
    tipoEmisions: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fieldsTable: [
        {
          key: 'numero', label: 'N°', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '5%' },
        },
        {
          key: 'fecha', label: 'Fecha Hora', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '22%' },
        },
        {
          key: 'origen', label: 'Origen', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '10%' },
        },
        {
          key: 'numero_factura', label: 'N° Factura', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '10%' },
        },
        {
          key: 'total', label: 'Importe', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '9%' },
        },
        {
          key: 'usuario', label: 'Usuario', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '15%' },
        },
        {
          key: 'estado', label: 'Estado', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '18%' },
        },
        {
          key: 'opciones', label: '', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '10%' },
        },
      ],
      tipoEmisionSelected: null,
      conexionSiat: false,
      venta: null,
      factura: null,
    };
  },
  methods: {
    colorIconEstadoFactura(codClasificador) {
      let color = null;
      if (codClasificador === TIPO_EMISION.FUERA_LINEA) {
        color = {
          color: 'orange',
          background: 'orange',
        };
      }
      if (codClasificador === TIPO_EMISION.CONTINGENCIA) {
        color = {
          color: 'red',
          background: 'red',
        };
      }
      return color;
    },
    async validacionSucursalFacturador(item, index) {
      this.tipoEmisionSelected = null;
      if (this.extrafacturaElectronica) {
        let puntoVentaFiler = null;
        puntoVentaFiler = this.siatPuntoVentas.filter((el) => (
          el.siat_sucursal_id === item.siat_sucursal_id
        ));
        if (!item.siat_sucursal_id) {
          util.showNotify('La sucursal Seleccionada no esta sincronizada con facturador', 'warn');
          return;
        } if (puntoVentaFiler.length === 0) {
          util.showNotify('La sucursal Seleccionada no esta vinculado con un punto de venta', 'warn');
          return;
        }
        const data = {
          siat_sucursal_id: item.siat_sucursal_id,
          siat_puntoventa_id: puntoVentaFiler.length > 0 ? puntoVentaFiler[0].siat_tipo_punto_venta_id : '',
          medico_id: '',
          origen: 1, // erp
        };
        this.venta = item;
        await this.synFacturaCreate(data, index);
        await this.getDataFacturaById();
      }
    },
    async synFacturaCreate(data, index) {
      try {
        const response = await axios.post('/synFactura/facturation/create', data);
        const dataConexion = (response.data.data.conexion.original) ? response.data.data.conexion : response.data.data.conexion.coneccion;
        const conexion = validarParametroFacturacionSiat(dataConexion, true, this.extrafacturaElectronica);

        this.conexionSiat = Array.isArray(conexion) ? false : conexion;
        this.tipoEmisionSelected = !this.conexionSiat ? this.tipoEmisions.find((el) => (
          el.cod_clasificador === TIPO_EMISION.FUERA_LINEA
        ))
          : this.tipoEmisions.find((el) => (
            el.cod_clasificador === TIPO_EMISION.EN_LINEA
          ));
        this.onChangeValueTipoEmisionSelected();
        this.disabledEstado(index);
      } catch (e) {
        util.showNotify(e.response.message, 'error');
      }
    },
    disabledEstado(index) {
      this.facturasPendientesSiats.forEach((el, index2) => {
        this.facturasPendientesSiats[index2].estado_validacion = false;
        if (index2 === index) {
          this.facturasPendientesSiats[index2].estado_validacion = this.conexionSiat;
        }
      });
    },
    async getDataFacturaById() {
      try {
        const data = {
          medico_id: '',
          origen: 1, // erp
          venta_id: this.venta.id,
        };
        const response = await axios.post('/synFactura/factura/getDataFacturaPendiente', data);
        const dataFactura = response.data.data;
        dataFactura.medico_id = null;
        dataFactura.origen = 1; // ERP
        this.factura = dataFactura;
      } catch (e) {
        util.showNotify(e.response.message, 'warn');
      }
    },
    onChangeValueTipoEmisionSelected() {
      if (this.tipoEmisionSelected.cod_clasificador === TIPO_EMISION.FUERA_LINEA) {
        this.tipoContingenciaSeleted = this.tipoContingencias.find((el) => (
          el.cod_clasificador === 2
        ));
      }
    },
    async storeFactura() {
      // ENVIA A FACTURADOR
      if (!this.conexionSiat) {
        util.showNotify('Se debe validar la venta antes de enviar!', 'warn');
        return;
      }
      try {
        const response = await axios.post('/synFactura/facturation/store', this.factura);
        const SIAT_VENTA_ID = response.data.siat_venta_id;
        const DATA_FACTURADOR = response.data.factura;
        this.storeSiatVenta(SIAT_VENTA_ID, DATA_FACTURADOR);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          this.$util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => this.$util.showNotify(texto, 'error'));
          });
        }
      }
    },
    async storeSiatVenta(siatVentaId, dataFacturador) {
      // GUARDAR LOS DATOS DE LA RESPUESTA DEL FACTURADOR
      try {
        const data = {
          venta_id: this.venta.id,
          siat_venta_id: siatVentaId,
          data_facturador: dataFacturador,
        };
        const response = await axios.post('/synFactura/factura/storeSiatFacturaPendiente', data);
        util.showNotify(response.data.message, 'success');
        this.$bvModal.hide('ModalBitacoraFacturador');
        this.$emit('storeFacturaPendiente', true);
      } catch (error) {
        util.showNotify(error.response.message, 'warn');
      }
    },
    reset() {
      this.conexionSiat = false;
      this.venta = null;
      this.factura = null;
      this.tipoEmisionSelected = null;
    },
  },
  computed: {
    ...mapFields('main', [
      'extrafacturaElectronica',
    ]),
  },
};
</script>

<style scoped>
  .en-linea {
      font-size: 18px;
      -webkit-border-radius: 50%;
      background:#3d8b40;
      color:#3d8b40;
      cursor:pointer;
  }
</style>
